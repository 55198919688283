<template>
  <div class="homePublic" style="margin-top: -120px">
    <section class="hero is-medium is-main is-darkNO">
      <div class="hero-body" style="padding: 0; margin: 0; padding-top: 60px; padding-bottom: 4rem">
        <div class="container" style="padding: 20px">
          <br />
          <br />

          <!-- 
   The creator platform, for bots

   Bots too, can earn money


 The #nocode platform for AI creators
  Create unique chat bots and monetize them
        -->
          <p class="title is-3">
            <!--         -->
            The #nocode platform for AI creators
          </p>
          <p class="subtitle is-3">
            <!-- -->

            Create unique chat bots and monetize them
          </p>

          <div style="max-width: 500px">
            <!--

                https://docs.google.com/forms/d/1BXjjwwHKvSF5otsk3TinUbIS42xZkcFaR1be27wpLgs/edit
                
            -->
            <a href="https://forms.gle/M99zMV2FWRsTBc8a7" target="_blank">
              <b-button
                label="Join Creator program"
                type="is-dark"
                style="text-transform: uppercase; letter-spacing: 1px"
              />
            </a>
          </div>
          <!-- 
          <b-button native-type="submit" type="  is-dark" style="text-transform: uppercase; letter-spacing: 1px">
            TRY IT NOW
          </b-button>
        -->
          <br />
          <em style="color: black; opacity: 0.2">
            <!-- -->
            Don't miss out on the AI gold rush
          </em>

          <!--     <em style="color: black; opacity: 0.2">Join the first AI creators community </em>  <qrform /> -->
        </div>
      </div>
    </section>
    <br />
    <br />

    <div class="section" style="display: none">
      <div class="container c1200 promoMenu">
        <div style="text-align: center">
          <div style="">
            <appStoreBtns style="display: none" />
          </div>
          <br />

          <h2 class="title is-2">Useful, personal and fun</h2>
          <h2 class="subtitle is-4">
            DM friendly bots engineered for specific tasks.
            <!-- tasks   - uses.-->
          </h2>
          <br />
        </div>

        <div v-show="false">
          <promoMenu />
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container c1200">
        <p class="title is-3">
          <!--     <qrform /> 
          For no-code creators  on OnlyBots  💥-->
          Tools for creativity 🚀
        </p>
        <p class="subtitle is-5">
          It takes less than 5 minutes to create a page.

          <!--  
          You can then sell it on our marketplace, or use it

             <qrform /> 
          Build specialized bots, without coding.-->
        </p>

        <div class="columns is-multiline">
          <div class="column is-4" v-for="feature in keyFeatures" :key="feature.title">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  {{ feature.title }}
                </p>
              </header>
              <div class="card-content">
                <div class="content" v-html="feature.description"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- eo col-->
      </div>
    </div>

    <!--  <div class="section pricing" id="pricing">
      <div style="text-align: center">
    
        <h2 class="title is-3">Profesional inboxes, fair prices.</h2>
        <h2 class="subtitle is-4" style="max-width: 780px; text-align: center; margin: auto">
          All plans includes a @BYE address, custom domains, email&nbsp;forwarding, unlimited mailboxes, unlimited users
          &amp;&nbsp;all&nbsp;core&nbsp;features.
        </h2>
        <br />
      </div>
      <div class="container c1200 pricing">
        <pricing />
      </div>
    </div>
        -->

    <div class="section" id=" ">
      <div class="container c1200" style="text-align: left">
        <div class="columns">
          <div class="column">
            <div class="box">
              <p class="title is-2">
                <!--     <qrform /> -->
                Be a part of the chatbot revolution.
              </p>
              <a href="https://forms.gle/M99zMV2FWRsTBc8a7" target="_blank">
                <b-button
                  label="Join Creator program"
                  type="is-dark"
                  style="text-transform: uppercase; letter-spacing: 1px"
                />
              </a>
              <!--  
              <waitlist /> -->
            </div>
          </div>
          <div class="column"></div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div style="display: none">
          <appStoreBtns />
        </div>
        <br />

        <br />

        <br />
        <p class=" ">© {{ new Date().getFullYear() }}. <strong>OnlyBots</strong> - Made with ❤️ by the beach 🌴</p>
      </div>
    </div>

    <!--  
    <div class="section">
      <div class="container c1200 guide">
        <guide />
      </div>
    </div>
 -->
    <theFooter />
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
//import projCard from "@/components/projCard.vue";
import waitlist from "@/components/waitlist.vue";
import qrform from "@/components/re/qrform.vue";
import BotList from "@/components/BotList.vue";

import guide from "@/components/re/guide.vue";
import promoMenu from "@/components/re/promoMenu.vue";

import appStoreBtns from "@/components/brochure/appStoreBtns.vue";
//import pricing
import pricing from "@/components/pricing.vue";
const keyFeatures = [
  {
    title: "Easy to build   📐 ", //🤖
    description: "No-code workflow. Bring your ideas and expertise to design an original AI tool.",
  },

  {
    title: "Fast & available   🚀", //🤖
    description:
      "Let anyone can chat with your bot, " +
      //   "   Define its purpose, its personality and how it interacts online." +
      "keep it for yourself, or embed it on a site.",
  },

  {
    title: "Earn 💰 👀", //🖼️  look emoji:  👀
    description:
      " Creators earn when people interact with their AIs. That fuels innovation and reward creative use of technology.",
  },
  {
    title: "Get visibility 🖼️", //🖼️  look emoji:  👀
    description: "Find an audience for your AI generated art, photos, poems and memes.",
  },

  {
    title: "Flexible  ", //🖼️  look emoji:  👀
    description: "Use our image generators or upload your creations. Use one of our AI models, or plug in your API.",
  },

  /*
  {
    title: "Chat with bots 💬",
    description: "Ask them questions, learn about specific topics, and discover fun personalities.",
  },

  {
    title: "Follow AI creators  👀", //🖼️  look emoji:  👀
    description: " Follow projects and creators you like. Discover the best AI content on the web.",
  },

  {
    title: "Launch your bot 🚀", //🤖
    description:
      " For your brand, or just for fun. " +
      "   Define its purpose, its personality and how it interacts online." +
      " Creators can earn money from their bots.",
  },

  */
];

export default {
  name: "Home",
  components: {
    // qrform, //
    //waitlist,
    appStoreBtns,
    //  guide,
    promoMenu,
    // BotList,
    // pricing,
    //  HelloWorld,
    // projCard,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "OnlyBots •  Made for AI creators  🤖",
    // all titles will be injected into this template
    // titleTemplate: "%s  🍔  ",
    //meta: [{ charset: "utf-8" }, { name: "viewport", content: "width=device-width, initial-scale=1" }],
  },
  data() {
    return {
      bots: [],
      keyFeatures,
      projs: [],
      loadingProj: true,
      // loadingOps: true,
    };
  },
  mounted() {
    var promo = {
      handle: "Add yours",
      avatar: "http://localhost:5599/img/bots/plus.webp",
      bio: "Design your own bot, and share it with the world", // "Launch a bot for your brand, or just for fun.   ",
    };
    /*
    window.API.getFeaturedBots({ brochureHome: 1 }).then((proj) => {
      this.bots = proj;
      this.bots.push(promo);
      this.loadingBots = false;
      window.prerenderReady = true;
      // console.log("PROJJ!", proj, proj.id);
    });*/
  },
};
</script>
<style scoped>
.container.c1200 {
  max-width: 1100px !important ;
}
.hero.is-main {
  background-color: #167df0;
  color: #fff;
  background: linear-gradient(150deg, #ffc92f, #ff185c, #f316d5, #b81ed2);
  /*
  background: linear-gradient(139deg, #42ffe5, #55ff2b, #e4ff00);

  background: linear-gradient(139deg, #ffffff, #fbff00, #d6ff00);*/
}

/* mobile smaller title and p text size */
@media screen and (max-width: 768px) {
  /* heros */
  .hero.is-main {
    background: linear-gradient(139deg, #ffffff, #fbff00, #d6ff00);
  }

  .title.is-2 {
    font-size: 1.8rem !important;
  }
  .subtitle.is-4 {
    font-size: 1.1rem !important;
  }
}
</style>
